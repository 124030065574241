body {
  margin: 0;
  font-family: "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  /*fdfdfd*/
  /*757575*/
}

a {
  color: inherit;
  text-decoration: none;
}
